import { Overlay } from '@molecules/modal/modal.styles'
import { SmallStyledSpinner, StyledSpinner } from './spinner.styles'

export const Spinner = () => {
  return (
    <Overlay>
      <StyledSpinner aria-label="loading spinner" />
    </Overlay>
  )
}

export const SmallSpinner = () => (
  <SmallStyledSpinner aria-label="loading spinner" />
)
