import {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from 'next'
import { useAuth } from 'helpers/hooks/useAuth/useAuth'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useCookies } from 'helpers/hooks/useCookies/useCookies'
import { ROUTE } from 'helpers/utils/routes'
import {
  handleTracking,
  segmentIdentification,
} from 'helpers/utils/analytics/analytics'
import { EVENT_NAMES } from 'helpers/utils/analytics/analytics.data'
import { captureException } from '@sentry/nextjs'
import { Spinner } from '@atoms/spinner/spinner'

type TServerSide = InferGetServerSidePropsType<typeof getServerSideProps>

const Index: NextPage<TServerSide> = ({ authTokens }) => {
  const { token, setToken, setRefreshToken, getEmployer } = useAuth()
  const router = useRouter()

  const saveTokensAndRedirect = async (authTokens: {
    accessToken: string
    refreshToken: string
  }) => {
    const bearerToken = `Bearer ${authTokens.accessToken}`
    setToken(bearerToken)
    setRefreshToken(authTokens.refreshToken)
    const {
      status: employerStatus = null,
      employerData = null,
      organisationData = null,
      error: employerError,
    } = await getEmployer(bearerToken)

    if (employerStatus === 200) {
      // Identify and redirect home or onboarding to complete user info
      segmentIdentification(employerData, organisationData)
      if (employerData?.userData?.id) {
        router.push(
          { pathname: ROUTE.HOME, query: { prefetchedEmployer: true } },
          ROUTE.HOME
        )
      } else {
        handleTracking(EVENT_NAMES.ONBOARDING_STARTED, {
          id: employerData.id,
          organisationId: organisationData.id,
          name: `${employerData.firstName} ${employerData.lastName}`,
        })
        router.push(ROUTE.ONBOARDING)
      }
    } else {
      captureException('Error getting employer info after SSO', {
        extra: {
          employerStatus,
          authTokens: JSON.stringify(authTokens),
          employerError: JSON.stringify(employerError),
        },
      })
      router.push(`${ROUTE.LOGIN}?SSOErr=1`)
    }
  }

  useEffect(() => {
    if (token) {
      router.push(ROUTE.HOME)
    } else {
      if (authTokens) {
        saveTokensAndRedirect(authTokens)
      } else {
        router.push(ROUTE.LOGIN)
      }
    }
  }, [])

  return <Spinner />
}

export default Index

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  // API tokens after SSO
  const authTokens = context.req.cookies['api_auth_tokens']

  if (!authTokens) {
    return { props: { authTokens: null } }
  }

  useCookies.deleteServerSideCookie(context.res, 'api_auth_tokens')

  return {
    props: {
      authTokens: JSON.parse(authTokens),
    },
  }
}
