import { css, CSSProp } from 'styled-components'

export const scrollbar = (): CSSProp => css`
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.blueLight};
  }
`
