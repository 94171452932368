import styled from 'styled-components'
import { spin } from '~animations/spin'
import { size } from '~functions/size'
import { breakpoint } from '~theme/breakpoint'

export const StyledSpinner = styled.div`
  ${({ theme }) => size(theme.spacing.xl)}
  border: ${({ theme }) =>
    `${theme.spacing.xs} solid ${theme.color.whiteDark}`};
  border-top: ${({ theme }) =>
    `${theme.spacing.xs} solid ${theme.color.primary}`};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;

  @media (min-width: ${breakpoint.tablet}) {
    ${size('80px')}
    border-width: ${({ theme }) => theme.spacing.s};
  }
`

export const SmallStyledSpinner = styled(StyledSpinner)`
  ${({ theme }) => size(theme.spacing.m)};
  border: ${({ theme }) =>
    `${theme.spacing.xxs} solid ${theme.color.blackLight}`};
  border-top: ${({ theme }) =>
    `${theme.spacing.xxs} solid ${theme.color.primary}`};
`
