import styled, { css, CSSProp } from 'styled-components'
import { load } from '~animations/load'
import { flex } from '~functions/flex'
import { scrollbar } from '~functions/scrollbar'
import { size } from '~functions/size'

export const Overlay = styled.div`
  ${flex()};
  ${size('100%')}
  animation: ${load} 0.2s;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: ${({ theme }) => theme.extra.borderRadius.m};
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
`

export const StyledModal = styled.section<{ customStyles?: CSSProp }>`
  ${flex('row', 'stretch', 'flex-start')};
  background: ${({ theme }) => theme.color.whiteDark};
  border-radius: ${({ theme }) => theme.extra.borderRadius.m};
  max-height: calc(100vh - 24px);
  max-width: 896px;
  min-height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: relative;

  transition: transform 0.3s ease-in-out;
  ${scrollbar()}
  ${({ customStyles }) => customStyles || ''}
`

export const SideWrapper = styled.div`
  ${flex()};
  background-color: ${({ theme }) => theme.color.white};
  max-width: 264px;
  min-width: 232px;
  padding: ${({ theme }) => theme.spacing.m};
`

export const ModalContent = styled.div`
  ${flex('column', 'flex-start', 'space-between')};
  gap: ${({ theme }) => theme.spacing.m};
  padding: ${({ theme }) => theme.spacing.xl};
  position: relative;
  width: 100%;
`

export const SideImage = styled.img`
  ${size('auto', '100%')};
  display: block;
  object-fit: contain;
`

export const Body = styled.div`
  ${flex('column', 'flex-start')};
  ${size('100%')}
`

export const Navigation = styled.nav`
  ${flex()};
  align-self: flex-end;
  margin-top: auto;
`

export const Close = styled.button`
  position: absolute;
  right: ${({ theme }) => theme.spacing.xl};
  top: ${({ theme }) => theme.spacing.xl};
  z-index: 11;
`

export const CloseIcon = styled.img``

export const customStyledActionButton = css`
  margin-left: ${({ theme }) => theme.spacing.m};
  min-width: fit-content;
`

export const customStyledSubtitle = css`
  margin-top: ${({ theme }) => `-${theme.spacing.m}`};
`
